import React from "react"
import { Link } from "gatsby"
import { Reply } from "react-bootstrap-icons"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import TextSmall from "../TextSmall"
import SectionText from "../SectionText"

const BlogComments = ({
  personName = "John C. Valenza",
  date = "Jun 20, 2021 | 3.30 pm",
  text = "OMG! I cannot believe that I have got a brand new landing page after getting Fastland. It was super easy to create, edit and publish.",
  className = "",
  imageData,
}) => {
  const image = getImage(imageData)
  return (
    <>
      <div
        className={`flex items-start justify-start w-full gap-3 tablet:gap-6 ${className}`}
      >
        <GatsbyImage
          image={image}
          alt=""
          className="flex-shrink-0 object-cover w-12 h-12 border-4 border-white rounded-full shadow-lg tablet:w-16 tablet:h-16"
        />
        <div className="flex flex-col w-full gap-6 ">
          <div className="flex items-center justify-between ">
            <div className="flex flex-col">
              <SectionText className="!font-medium !pb-0 !text-neutral-700">
                {personName}
              </SectionText>
              <TextSmall>{date}</TextSmall>
            </div>
            <Link to="#CommentForm">
              <button className="flex items-center justify-center gap-2">
                <Reply className="text-primary-600 text-body-xl" />
                <p className="font-medium text-primary-600">Reply</p>
              </button>
            </Link>
          </div>
          <TextSmall className="tablet:w-[80%]">{text}</TextSmall>
        </div>
      </div>
    </>
  )
}
export default BlogComments
