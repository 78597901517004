import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Section from "../../components/Section"
import Container from "../../components/Container"
import SectionTitle from "../../components/SectionTitle"
import SectionText from "../../components/SectionText"
import TextSmall from "../../components/TextSmall"

const BlogHero = () => {
  return (
    <>
      <Section
        isHero
        className="overflow-hidden !pb-2 tablet:!pb-20 laptop:!pb-[120px]"
      >
        <Container>
          {/* wrap */}
          <div className="grid items-center justify-center grid-cols-1 laptop:grid-cols-12">
            {/* left-part */}
            <div className="col-span-6 pb-20 laptop:pr-10 laptop:pb-0">
              <div className="flex flex-col gap-4">
                <div className="flex items-center gap-5">
                  <Link to="/blog-2">
                    <p className="p-2 font-medium text-body-sm rounded-[20px] text-neutral-800 bg-error-100">
                      SEO
                    </p>
                  </Link>
                  <p className="font-semibold text-body-md text-primary-600">
                    Jun 20, 2025
                  </p>
                </div>
                <div>
                  <SectionTitle className="">
                    Top 10 SEO tips to follow in
                    <span className="text text-primary-600"> 2025 </span>
                  </SectionTitle>

                  <SectionText className="tablet:pr-10 ">
                    Leverage agile frameworks to provide a robust synopsis for
                    high level overviews. Iterative approaches to corporate.
                  </SectionText>
                  <div className="flex items-center gap-4 pt-2 laptop:pt-0">
                    <StaticImage
                      src="../../images/support-avatar-1.png"
                      alt=""
                      className="object-contain w-12 h-12 rounded-full "
                    />
                    <div className="flex flex-col ">
                      <p className="font-medium text-body-lg text-neutral-800">
                        Daniel C. Sly
                      </p>
                      <TextSmall>Frontend Engineer</TextSmall>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* right-part */}
            <div className="flex justify-center col-span-6 ">
              <div className="relative inline-flex items-center justify-center -z-10 laptop:items-end laptop:justify-end">
                <div className="flex items-center justify-center ">
                  <StaticImage
                    src="../../images/blog-details-hero.png"
                    alt=""
                    className="relative z-20 inline-block rounded-3xl"
                  />
                </div>
                <div className="absolute h-16 z-10 w-16 tablet:h-[100px] tablet:w-[100px] bg-success-100 top-0 right-0 translate-x-1/4 laptop:translate-x-1/2 -translate-y-1/2">
                  <div className="absolute h-9 w-9 bg-secondary-200 bottom-full left-full"></div>
                </div>
                <div
                  className="absolute h-16 w-16 tablet:h-[100px] tablet:w-[100px] z-30
                 bg-primary-100 bottom-0 left-0 -translate-x-1/4 laptop:-translate-x-1/2 translate-y-1/2"
                >
                  <div className="absolute w-10 h-10 bg-secondary-200 top-full left-full"></div>
                </div>
                <div className="w-[60vw] h-[60vw] -translate-x-1/2 bg-primary-25 absolute left-1/2 bottom-0 translate-y-[10%]"></div>
              </div>
            </div>
          </div>
        </Container>
      </Section>
    </>
  )
}

export default BlogHero
