import React from "react"
import { Link } from "gatsby"

export const TagCard = ({ className = "", text = "UI/UX" }) => {
  return (
    <>
      <Link to="/blog-1">
        <p
          className={`p-2 font-medium text-body-sm rounded-[20px] text-neutral-800 bg-primary-100 ${className}`}
        >
          {text}
        </p>
      </Link>
    </>
  )
}

const BlogTags = ({ title = "Tags", className = "" }) => {
  return (
    <>
      <div
        className={`flex flex-col gap-8 px-8 bg-white shadow-lg py-9 rounded-2xl ${className}`}
      >
        <h6 className="font-bold text-display-xs text-neutral-800">{title}</h6>
        <div className="flex flex-col gap-6">
          <div className="flex items-start justify-start gap-3 laptop:items-center laptop:justify-center">
            <TagCard className="!bg-primary-100" text="UI/UX"></TagCard>
            <TagCard className="!bg-secondary-50" text="Code"></TagCard>
            <TagCard className="!bg-error-100" text="SEO"></TagCard>
          </div>
          <div className="flex items-start justify-start gap-3 laptop:items-center laptop:justify-center">
            <TagCard className="!bg-success-50" text="JavaScript"></TagCard>
            <TagCard className="!bg-secondary-50" text="CSS"></TagCard>
            <TagCard className="!bg-success-50" text="React"></TagCard>
          </div>
          <div className="flex items-start justify-start gap-3 laptop:items-center laptop:justify-center">
            <TagCard className="!bg-secondary-50" text="Vue"></TagCard>
            <TagCard className="!bg-primary-100" text="SVG"></TagCard>
            <TagCard className="!bg-success-50" text="Color"></TagCard>
          </div>
        </div>
      </div>
    </>
  )
}
export default BlogTags
