import React from "react"
import { GatsbySeo } from "gatsby-plugin-next-seo"
import {
  ChevronLeft,
  ChevronRight,
  Facebook,
  Linkedin,
  Twitter,
} from "react-bootstrap-icons"
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import PageWrapper from "../components/PageWrapper"
import BlogHero from "../sections/heroes/BlogHero"
import Button from "../components/Button"
import SectionText from "../components/SectionText"
import Container from "../components/Container"
import Section from "../components/Section"
import SectionTitle from "../components/SectionTitle"
import BlogComments from "../components/blogs/BlogComments"
import { TagCard } from "../components/blogs/BlogTags"

const BlogDetails = ({ data }) => {
  const { image1, image2, image3, image4 } = useStaticQuery(graphql`
    query {
      image1: allFile(
        filter: { relativePath: { eq: "blog-comments-avater-1.png" } }
      ) {
        nodes {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      image2: allFile(
        filter: { relativePath: { eq: "blog-comments-avater-2.png" } }
      ) {
        nodes {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      image3: allFile(
        filter: { relativePath: { eq: "blog-comments-avater-3.png" } }
      ) {
        nodes {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      image4: allFile(
        filter: { relativePath: { eq: "blog-comments-avater-4.png" } }
      ) {
        nodes {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  `)
  return (
    <>
      <PageWrapper
        footerConfig={{
          hasBottomSocial: true,
          className: "bg-neutral-50",
        }}
      >
        <GatsbySeo
          title={`Kloft - Top 10 SEO tips to follow in 2023`}
          description={`We serve globally to modernise static sites to take advantage of the blazing speeds of JAMStack while eliminating monthly maintenance and even probably, server costs.`}
        />
        <BlogHero></BlogHero>

        <Section>
          <Container className="relative flex flex-col gap-20 laptop:gap-28">
            <div className="flex flex-col gap-16 tablet:gap-20 laptop:gap-28 max-w-[700px] mx-auto ">
              <div className="flex flex-col gap-14">
                <article className="flex-shrink-0 w-full max-w-full prose">
                  <h2>Introduction</h2>
                  <p>
                    Efficiently enable enabled sources and cost effective
                    products. Completely synthesize principle-centered
                    information after ethical communities. Efficiently innovate
                    open-source infrastructures via inexpensive materials.
                  </p>
                  <p>
                    <a
                      href="https://github.com/"
                      className="text-primary-600"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Compellingly embrace
                    </a>{" "}
                    empowered e-business after user friendly intellectual
                    capital. Interactively actualize front-end processes with
                    effective convergence. Synergistically deliver performance
                    based methods of empowerment whereas distributed expertise.
                  </p>
                  <p>
                    Leverage agile frameworks to provide a robust synopsis for
                    high level overviews. Iterative approaches to corporate
                    strategy foster collaborative thinking to further the
                    overall value proposition. Organically grow the holistic
                    world view of disruptive innovation via workplace diversity
                    and empowerment.
                  </p>
                  <p>
                    Bring to the table win-win survival strategies to ensure
                    proactive domination. At the end of the day, going forward,
                    a new normal that has evolved from generation X is on the
                    runway heading towards a streamlined cloud solution. User
                    generated content in real-time will have multiple
                    touchpoints for offshoring.
                  </p>
                  <p>
                    Capitalize on low hanging fruit to identify a ballpark value
                    added activity to beta test. Override the digital divide
                    with additional clickthroughs from DevOps. Nanotechnology
                    immersion along the information highway will close the loop
                    on focusing solely on the bottom line.
                  </p>
                  <div>
                    <StaticImage
                      alt=""
                      className="rounded-3xl"
                      src="../images/blog-details-1.png"
                    />
                  </div>
                  <p>
                    Enthusiastically mesh long-term high-impact infrastructures
                    vis-a-vis efficient customer service. Professionally fashion
                    wireless leadership rather than prospective experiences.
                    <a
                      href="https://github.com/"
                      className="text-primary-600"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Energistically myocardinate
                    </a>{" "}
                    clicks-and-mortar testing procedures whereas next-generation
                    manufactured products.{" "}
                  </p>
                  <blockquote></blockquote>

                  <blockquote>
                    <p>
                      “Globally harness multimedia based collaboration and
                      idea-sharing with backend products. Continually whiteboard
                      superior opportunities via covalent scenarios.”
                    </p>
                    <footer>
                      <small>— Olivia Rhye, Product Designere</small>
                    </footer>
                  </blockquote>
                  <h3>Other resources</h3>
                  <p>
                    Collaboratively administrate empowered markets via
                    plug-and-play networks. Dynamically procrastinate B2C users
                    after installed base benefits. Dramatically visualize
                    customer directed convergence without revolutionary ROI.
                  </p>
                  <ol>
                    <li>
                      Compellingly supply just in time catalysts for change
                      through top-line potentialities.
                    </li>
                    <li>
                      Efficiently initiate world-class applications after
                      client-centric infomediaries.
                    </li>
                    <li>
                      Enthusiastically engage fully tested process improvements
                      before top-line platforms.
                    </li>
                  </ol>
                  <div>
                    <StaticImage
                      alt=""
                      className="rounded-3xl"
                      src="../images/blog-details-2.png"
                    />
                  </div>
                  <h3>Various tools</h3>
                  <p>
                    Efficiently unleash cross-media information without
                    cross-media value. Quickly maximize timely deliverables for
                    real-time schemas. Dramatically maintain clicks-and-mortar
                    solutions without functional solutions.
                  </p>
                  <p>
                    Compellingly embrace empowered e-business after user
                    friendly intellectual capital. Interactively actualize
                    front-end processes with effective convergence.
                    Synergistically deliver performance based methods of
                    empowerment whereas distributed expertise.
                  </p>
                  <p>
                    Objectively integrate enterprise-wide strategic theme areas
                    with functionalized infrastructures. Interactively
                    productize premium technologies whereas interdependent
                    quality vectors. Rapaciously utilize enterprise experiences
                    via 24/7 markets.
                  </p>
                </article>
                {/* tages */}
                <div className="flex flex-col gap-6 border py-9 rounded-2xl border-neutral-200">
                  <div className="flex items-center justify-center gap-3">
                    <TagCard className="!bg-primary-100" text="UI/UX"></TagCard>
                    <TagCard className="!bg-secondary-50" text="Code"></TagCard>
                    <TagCard className="!bg-error-100" text="SEO"></TagCard>
                  </div>
                  <div className="flex items-center justify-center gap-3">
                    <TagCard
                      className="!bg-success-50"
                      text="JavaScript"
                    ></TagCard>
                    <TagCard
                      className="!bg-error-50"
                      text="Lighthouse"
                    ></TagCard>
                    <TagCard className="!bg-secondary-50" text="CSS"></TagCard>
                    <TagCard className="!bg-success-50" text="React"></TagCard>
                  </div>
                </div>
              </div>
              {/* button */}
              <div className="flex flex-col items-center justify-between gap-8 laptop:flex-row">
                <div className="flex flex-col items-start justify-start gap-6">
                  <SectionText className="!pb-0 hidden tablet:block text-primary-600 !font-medium">
                    Previous:
                  </SectionText>
                  <Link to="/blog-details">
                    <div className="flex items-center gap-4">
                      <Link to="/blog-details">
                        <Button
                          className="text-black hover:text-neutral-50 bg-white !border border-neutral-300 h-10 w-10"
                          iconOnly={"false"}
                          icon={
                            <ChevronLeft className="font-bold text-body-xl" />
                          }
                        ></Button>
                      </Link>
                      <p className="font-bold hover:text-primary-600 duration-300 text-body-md text-neutral-700 w-[244px]">
                        Boost your customer base with these 7 tips
                      </p>
                    </div>
                  </Link>
                </div>

                <div className="flex flex-col items-end justify-end gap-6">
                  <SectionText className="!pb-0 text-primary-600 hidden tablet:block !font-medium">
                    Next:
                  </SectionText>
                  <Link to="/blog-details">
                    <div className="flex items-center gap-4">
                      <p className="font-bold hover:text-primary-600 duration-300 text-body-md text-neutral-700 w-[244px]">
                        Make your users happy with these simple tricks
                      </p>
                      <Link to="/blog-details">
                        <Button
                          className="text-black hover:text-neutral-50 bg-white !border border-neutral-300 h-10 w-10"
                          iconOnly={"false"}
                          icon={
                            <ChevronRight className="font-bold text-body-xl" />
                          }
                        ></Button>
                      </Link>
                    </div>
                  </Link>
                </div>
              </div>
              {/* social-link */}
              <div className="flex flex-col items-center gap-6 tablet:gap-10 desktop:absolute desktop:top-0 desktop:left-5 ">
                <SectionText className="!pb-0">Share this story</SectionText>
                <div className="flex flex-row flex-wrap items-center gap-4 desktop:flex-col ">
                  <Link to="https://twitter.com/">
                    <Button
                      className="text-black hover:text-neutral-50 bg-white !border border-neutral-300 group h-10 w-10 !px-0 !py-0"
                      iconOnly={"false"}
                      icon={
                        <Twitter className="font-bold text-neutral-400 group-hover:!text-white text-body-xl" />
                      }
                    ></Button>
                  </Link>
                  <Link to="https://facebook.com/">
                    <Button
                      className="text-black hover:text-neutral-50 bg-white !border border-neutral-300 group h-10 w-10 !px-0 !py-0"
                      iconOnly={"false"}
                      icon={
                        <Facebook className="font-bold text-neutral-400 group-hover:!text-white text-body-xl" />
                      }
                    ></Button>
                  </Link>
                  <Link to="https://www.linkedin.com/">
                    <Button
                      className="text-black hover:text-neutral-50 bg-white !border border-neutral-300 h-10 w-10 !px-0 !py-0 group"
                      iconOnly={"false"}
                      icon={
                        <Linkedin className="font-bold text-neutral-400 group-hover:!text-white text-body-xl" />
                      }
                    ></Button>
                  </Link>
                </div>
              </div>
              {/* comments */}
              <div className="flex flex-col gap-6 tablet:gap-14">
                <SectionTitle className="text-center">Comments</SectionTitle>
                {/* cards */}
                <div className="flex flex-col items-end justify-center gap-16 max-w-[728px] w-full flex-shrink-0 mx-auto">
                  {/* card */}
                  <BlogComments
                    date="Jun 20, 2021 | 3.30 pm"
                    className=""
                    text="OMG! I cannot believe that I have got a brand new landing page after getting Fastland. It was super easy to create, edit and publish."
                    personName="John C. Valenza"
                    imageData={image1.nodes[0]}
                  ></BlogComments>
                  <BlogComments
                    date="Jun 20, 2021 | 3.50 pm"
                    className="max-w-[624px]"
                    text="Objectively integrate enterprise-wide strategic theme areas with functionalized infrastructures. Interactively productize premium technologies whereas interdependent quality vectors."
                    personName="Lois T. Lin"
                    imageData={image2.nodes[0]}
                  ></BlogComments>
                  <BlogComments
                    date="Jun 21, 2021 | 4.00 pm"
                    className=""
                    text="Seamlessly visualize quality intellectual capital without superior collaboration and idea-sharing. Holistically pontificate installed base portals after maintainable products."
                    personName="Brian N. Dawson"
                    imageData={image3.nodes[0]}
                  ></BlogComments>{" "}
                  <BlogComments
                    date="Jun 22, 2021 | 10.00 am"
                    className="max-w-[624px]"
                    text="Energistically scale future-proof core competencies vis-a-vis impactful experiences. Dramatically synthesize integrated schemas with optimal networks."
                    personName="Lisa T. McCray"
                    imageData={image4.nodes[0]}
                  ></BlogComments>
                </div>
              </div>
              {/* input */}
              <div
                className="flex flex-col gap-6 tablet:gap-12"
                id="CommentForm"
              >
                <h6 className="font-bold text-body-xl laptop:text-display-xs text-neutral-800">
                  Share your response
                </h6>
                <form className="flex flex-col gap-6">
                  <div className="flex flex-col gap-6 tablet:flex-row">
                    <label className="block w-full">
                      <span className="font-medium text-neutral-700">Name</span>
                      <input
                        type="text"
                        className="block w-full mt-1 rounded-lg shadow-xs border-neutral-300 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50"
                        placeholder="John Doe"
                        required
                        name="name"
                      />
                    </label>
                    <label className="block w-full">
                      <span className="font-medium text-neutral-700">
                        Email
                      </span>
                      <input
                        type="email"
                        className="block w-full mt-1 rounded-lg shadow-xs border-neutral-300 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50"
                        placeholder="johndoe@example.com"
                        required
                        name="email"
                      />
                    </label>
                  </div>
                  <label className="block">
                    <span className="font-medium text-gray-700">Message</span>
                    <textarea
                      className="block w-full mt-1 rounded-lg shadow-xs border-neutral-300 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 "
                      rows="3"
                      placeholder="Your comment here..."
                      required
                      name="message"
                    ></textarea>
                  </label>
                  <label className="inline-flex items-center">
                    <input
                      type="checkbox"
                      className="rounded shadow-sm border-neutral-300 text-primary-600 focus:border-primary-300 focus:ring focus:ring-offset-0 focus:ring-primary-200 focus:ring-opacity-50"
                      required
                      name="check"
                    />
                    <span className="ml-2">
                      I agreee to the terms and condition.
                    </span>
                  </label>

                  <Button type="submit" size={"xl"}>
                    Submit
                  </Button>
                </form>
              </div>
            </div>
          </Container>
        </Section>
      </PageWrapper>
    </>
  )
}

export default BlogDetails
